@import url('./variables.css');

/* -----------------------Font--------------------------- */
/* @font-face {
  font-family: "YJfont";
  src: url("../fonts/YJfont.ttf");
}
@font-face {
  font-family: "Ycomputer";
  src: url("../fonts/Ycomputer.ttf");
} */
@font-face {
  font-family: 'GangwonEdu_OTFBoldA';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEdu_OTFBoldA.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
}

body {
  /* background-color: var(--main-bg-color); */
  /* transition: background-color 0.7s ease; */
  max-width: 500px;
  width: 100%;
  margin: auto !important;
  background: linear-gradient(to bottom right, #eafffe, #aef9ef);
}

.bg-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* 배경 이미지를 다른 요소 뒤로 숨김 */
  background-image: url('../assets/img/bgImage.png');
  background-repeat: repeat;
  overflow: hidden;
  border-radius: 8px;
}

.App-logo {
  height: 40vmin;
  display: block;
}
.blueBg {
  background-color: var(--main-bg-color_blue);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
  .App-logo:hover {
    animation: none;
    filter: drop-shadow(0 0 4px #222);
    cursor: pointer;
  }
}

.App-logo.logoBig {
  animation: App-logo-big 0.7s ease-in forwards;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-big {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(2.3);
    opacity: 0;
  }
}

/* Header */

button.MuiTab-root {
  min-width: 50%;
}
.Component-root-79 {
  margin-right: 0;
}

/* Content */
.content {
  /* background-color: black;
  padding: 20px; */
  /* display: flex;
  justify-content: center; */
}

h1 {
  font-size: 2rem;
  margin: 5px auto;
}
h2 {
  font-size: 1rem;
  margin-bottom: 5px;
}

/* PC , 테블릿 가로 (해상도 768px ~ 1023px)*/
@media all and (max-width: 1023px) {
}
/* 모바일 가로, 테블릿 세로 (해상도 480px ~ 767px)*/
@media all and (max-width: 767px) {
}

/* 모바일 가로, 테블릿 세로 (해상도 ~ 479px)*/
@media all and (max-width: 479px) {
}

.testImg {
}

/* Profile */
.profileCont {
  line-height: 1.5;
}

/* spinner */
/* In your CSS file or <style> tag */
@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
